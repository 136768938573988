import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Section from "../../components/template-partials/global-components/section"
import ShareButtons from "../../components/template-partials/global-components/share-buttons"
import InteriorMenu from "../../components/template-partials/global-components/interior-menu"
import InteriorPageHeader from "../../components/template-partials/global-components/interior-page-header"
import InteriorPageFooter from "../../components/template-partials/global-components/interior-page-footer"
import FancyImage from "../../components/template-partials/global-components/fancy-image"
import SupportAndCommunitySubMenu from "../../components/template-partials/support-and-community/support-and-community-submenu"

import {
  UpNextCard,
  ExploreCard,
} from "../../components/template-partials/global-components/footer-cards"

import headingBg from "../../images/7.0-headerbg.jpg"
import headingRound from "../../images/mental-health-nav-image.png"

import takeBreak from "../../images/7.2.2_takeabreak.jpg"
import feelHealthy from "../../images/7.2.4_feelhealthy.jpg"
import helpWithTasks from "../../images/7.2.3_helpwithtasks.jpg"
import reduceStress from "../../images/7.2.5_reducingstress.png"
import takingCare from "../../images/7.2.6_takingcare.png"
import mentalHealth from "../../images/2.1.06_mentalhealth.png"

import asking from "../../images/7.2-asking.jpg"
import takingCareFam from "../../images/7.2-taking-care.jpg"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons"

//Cool! Export your sass Vars from a top line and use them in JS!
import * as SassVars from "../../components/variables.module.scss"
import VideoPlayer from "../../components/VideoPlayer"

const CaringCaregiverPage = () => {
  return (
    <Layout>
      <SEO
        title="Caring for the Neuroblastoma Caregiver"
        description="Caring for a child with neuroblastoma can take its toll both mentally and physically. Finding time to care for yourself helps to ensure you are able to care for your child. Here you’ll find some effective tools for self-care."
      />

      <InteriorPageHeader
        backgroundImage={headingBg}
        roundImage={headingRound}
        alt="Caring for the Caregiver"
      >
        <h1 className="section__heading section__heading--green h1">
          Caring for the Caregiver
        </h1>
      </InteriorPageHeader>

      {/* style={{backgroundColor: SassVars.viridian}} */}

      <Section className="interior-page">
        <div className="row row--inner">
          <div className="columns small-12 large-4">
            <InteriorMenu title={`Support And Community`}>
              <SupportAndCommunitySubMenu />
            </InteriorMenu>
          </div>
          <div className="columns">
            <div className="row">
              <div className="columns">
                <ShareButtons />
              </div>
            </div>
            <div className="row">
              <div className="columns">
                <h2 className="tc-barney h2 jost-med">
                  How to care for yourself
                </h2>
                <p>
                  Caring for a child with cancer can affect you physically as well as emotionally. You cannot stop the impact of illness on your child, but there are many things you can do to take care of your personal well-being and make sure that your own needs are met. Taking time to practice self-care will help you maintain your physical and emotional strength and will allow you to continue providing good care for your child and family.{" "}
                </p>
                <p>
                  Below are examples of some effective tools for self-care.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Section style={{ backgroundColor: SassVars.paleBlue }}>
        <div className="row row--inner">
          <div className="columns">
            <h3 className="tc-barney jost-med h2">
              Asking for and accepting help
            </h3>
            <p>
              A little help can go a long way, and your support network and community may ask how they can be there for you and your family. Be specific about what you need. Sometimes help with chores can be just as valuable as a listening ear. Keep a list of tasks that need to be done (eg, making meals, taking other children to and from school, home care, laundry, etc.) and take them up on their offer to help. Reducing the tasks that require your attention can allow you the time and energy you need to remain focused on caring for your child.
            </p>
          </div>
          <div className="columns large-4 large-text-right small-12 text-center">
            <FancyImage alt="asking for and accepting help" url={asking} />
          </div>
        </div>
      </Section>

      <Section>
        <div className="row row--inner">
          <div className="columns">
            <h3 className="tc-barney jost-med h2">Setting goals</h3>
            <p>
              It can be helpful to set personal goals or decide what you would like to achieve during different phases of therapy. Some examples are:{" "}
            </p>
            <div className="row lg_mt-1 lg_mb-1 mt-2 mb-4">
              <div className="columns large-4 small-12 text-center">
                <img alt="" src={takeBreak} style={{ maxWidth: `116px` }} />
                <p className="lg_mt-1 mt-1">Take a break</p>
              </div>
              <div className="columns large-4 small-12 text-center">
                <img alt="" src={helpWithTasks} style={{ maxWidth: `144px` }} />
                <p className="lg_mt-1 mt-1">
                  Find someone to help with caregiving tasks
                </p>
              </div>
              <div className="columns large-4 small-12 text-center">
                <img alt="" src={feelHealthy} style={{ maxWidth: `112px` }} />
                <p className="lg_mt-1 mt-1">
                  Do things that make you feel healthier and emotionally stronger</p>
              </div>
            </div>
            <p className="lg_mt-2 mt-2">You are more likely to reach a goal if you break it down into smaller steps. Make an action plan by deciding which step to take first, and when. It is okay to adjust as you go!</p>
          </div>
        </div>
      </Section>

      <Section style={{ backgroundColor: SassVars.honeydew }}>
        <div className="row row--inner align-justify align-middle">
          <div className="columns large-7 small-12">
            <h3 className="tc-barney jost-med h2">Reducing personal stress</h3>
            <p>Your level of stress is influenced by many factors. How you perceive an event affects how you respond and cope. Below are a few helpful steps to keep in mind when managing stressful situations including, but not limited to, caring for a child with neuroblastoma.</p>
            <ul className="ul--dotted">
              <li>
                Recognize warning signs as early as possible and make changes before you feel overwhelmed. These warning signs might include irritability, sleep disturbance, or forgetfulness
              </li>
              <li>Figure out sources of stress</li>
              <li>Work out what you can change and what you can’t</li>
              <li>
                Participate in a stress-reducing activity. This can help you gain back a feeling of control
              </li>
            </ul>
            <p>
              Stress reducers can be simple activities like walking and other forms of exercise, gardening, meditation, or having coffee with a friend. Participate in pleasant, nurturing activities that bring you joy.{" "}
            </p>
          </div>
          <div className="columns large-4 large-text-right small-8 small-offset-2 large-offset-0 text-center">
            <img src={reduceStress} alt="" />
          </div>
        </div>
      </Section>

      <Section>
        <div className="row row--inner align-justify small-col-reverse">
          <div className="columns lg_mt-4 large-4 large-text-left small-8 small-offset-2 large-offset-0 text-center">
            <img src={takingCare} alt="" />
          </div>
          <div className="columns large-7 small-12">
            <h3 className="tc-barney jost-med h2">
              Taking care of your physical health
            </h3>
            <p>
              Sleep, nutrition, and exercise are key components to good physical health. Getting a good night’s rest can benefit your physical health and mood. It can also improve your decision-making skills. Eating well promotes good health by building strength and stamina, and by aiding the immune system’s ability to fight illness. Exercise is shown to improve sleep and lessen tension and depression, and it may increase energy and alertness.{" "}
            </p>
            <p>
              As a caregiver, it may be difficult to ensure these needs are met
              when you do not have a lot of control over your physical
              environment. The key here is finding small ways of adapting to the
              situation to better support your physical health. Remember, small
              changes can have big impacts. For example:
            </p>
            <ul className="ul--dotted">
              <li>
                Getting a full night’s rest when you are staying in the hospital
                with your child can be challenging. Bringing a blanket, pillow,
                and foam topper from home to make the hospital couch a little
                more comfortable may help
              </li>
              <li>
                Getting to the gym daily for a workout might not be possible
                when your child is undergoing cancer treatment. Planning breaks
                for a quick walk is a great place to start
              </li>
            </ul>
          </div>
        </div>
      </Section>

      <Section style={{ backgroundColor: SassVars.paleBlue }}>
        <div className="row row--inner align-justify align-middle">
          <div className="columns large-7 small-12">
            <h3 className="tc-barney jost-med h2">
              Taking care of your emotional health
            </h3>
            <p>
              Our emotions are messages that we can learn from. Listen to them,
              then take action. Caregiving often involves a range of emotions.
              Some feelings are more comfortable than others. When you find that
              your emotions are intense, that might mean:
            </p>
            <ul className="ul--dotted">
              <li>You need to make a change in your caregiving situation</li>
              <li>Your levels of stress are increasing</li>
              <li>You have to ask for what you need</li>
            </ul>
            <p>
              Many caregivers benefit from working with a licensed mental health
              professional (like a therapist or a counselor) or support group to
              process their emotions. A busy caregiver can find help from these
              professionals and groups in person, via webchats, or by phone.
            </p>
          </div>
          <div className="columns large-4 large-text-right small-offset-2 small-8 large-offset-0 text-center">
            <img src={mentalHealth} alt="" />
          </div>
        </div>
      </Section>

      <Section>
        <div className="row row--inner align-justify align-middle">
          <div className="columns large-4 large-text-left small-12 text-center">
            <FancyImage alt="taking care of your family" url={takingCareFam} />
          </div>
          <div className="columns">
            <h3 className="tc-barney jost-med h2">
              Taking care of your family
            </h3>
            <p>
              It is common for siblings to have a difficult time understanding
              why their brother or sister is sick. Siblings can often feel
              forgotten and may need additional attention and support because
              your child with cancer requires so much focus.{" "}
              <a
                href="https://www.alexslemonade.org/childhood-cancer/for-families/supersibs"
                target="_blank"
                rel="noreferrer"
              >
                For more information on helping siblings cope, you can visit the
                SuperSibs page of the Alex’s Lemonade Stand website.{" "}
                <sup>
                  <FontAwesomeIcon icon={faExternalLinkAlt} />
                </sup>
              </a>
            </p>
          </div>
        </div>
      </Section>

      <Section style={{ backgroundColor: SassVars.honeydew }}>
        <div className="row row--inner align-justify">
          <div className="columns">
            <h3 className="tc-barney jost-med h2">
              Seeking the support of other caregivers
            </h3>
            <p>
              It is always helpful to talk to someone who understands what you
              are going through. A community of people dealing with similar life
              challenges can provide a safe space to share and learn from one
              another. Virtual support groups are accessible and allow for
              international networking.
            </p>
            <p>{" "}
              <a
                href="/support-and-community/support-groups/"
              >
                See Advocacy and Support Groups that may be able to provide the help you need.{" "}
              </a>
              </p>
          </div>
        </div>
      </Section>

      <Section style={{ backgroundColor: SassVars.paleBlue }}>
        <div className="row row--inner">
          <div className="columns">
            <h3 className="h2 tc-barney jost-med">Caring for the Caregiver</h3>
            <p>
              Hear from a social worker and nurse practitioner on the importance
              of self-care during your child’s treatment journey.
            </p>
            <VideoPlayer embedId="761928473" title="Caring for the Caregiver" />
          </div>
        </div>
      </Section>

      <InteriorPageFooter>
        <UpNextCard
          topic="Helpful Resources"
          title="Looking for additional resources?"
          lead="Find helpful resources for you and your child to use during your neuroblastoma journey."
          url="/stories-of-hope/devon-and-leah/"
        />
        <ExploreCard
          title="Talk to someone who's been through this before"
          lead="Connect with other neuroblastoma families through support groups and advocacy organizations."
          url="/support-and-community/support-groups/"
        />
      </InteriorPageFooter>
    </Layout>
  )
}

export default CaringCaregiverPage
